<template>
  <div>
    <h1>404 ページが存在しません</h1>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
